const theme = {};

theme.palette = {
    primary: [
        '#717cf2', // 0: Default
        '#6772E8', // 1: Darken 4%
        '#646FE5', // 2: Darken 5%
        'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
        '#7984FA', // 4: Lighten 3%
        '#C7C8D8', // 5: Fade 75%
        '#EDEFFF', // 6: Lighten 15%
        '#909BFF', // 7: Lighten 12%
        '#6772E8', // 8: Darken 2%
        '#3369e7', // 9: Algolia color
        '#8590FF', // 10: Lighten 8%
        '#2b69e6', // 11:
        '#5863D9', // 12: darken 10%
        '#7E89FF', // 13: Lighten 5%
    ],
    secondary: [
        'linear-gradient(#35395c, #2a2c44)', // 0: DarkBlue
        '#f3f4fb', // 1: LightBluish
        '#787da2', // 2: LightBlue
        '#ebecf3', // 3: LightBluish Darken 5%
        '#766998', // 4:
        '#202739', // 5: DarkBlue Darken 5%
        '#f5f6f8', // 6: LighterBluish
        '#DBDDE9', // 7: DarkBluish
        '#F6F8FB', // 8: LighterBluish Lighten 2%
        '#f0f2ff', // 9: LighterBluish Darken 3%
        '#1a1a1a', // 10: Sidebar submenu select
        'linear-gradient(to right, #f66e63, #f84072)', // 11: OrangeGradient
        '#f84072', // 12: OrangeGradient Default
        '#f66e63', // 13: OrangeGradient Other
        'linear-gradient(to right, #f84072, #f66e63)', // 14: OrangeGradientReverse
        '#434871', // 15: selectedMenuItem
    ],

    color: [
        '#FEAC01', // 0: Orange
        '#42299a', // 1: Purple
        '#F75D81', // 2: Pink
        '#8EE0B4', // 3: LimeGreen
        '#39435f', // 4: BlueShade
        '#FFCA28', // 5: Yellow
        '#F2BD1B', // 6: Yellow Darken 5%
        '#3b5998', // 7: Facebook
        '#344e86', // 8: Facebook Darken 5%
        '#dd4b39', // 9: Google Plus
        '#d73925', // 10: Google Plus Darken 5%
        '#e14615', // 11: Auth0
        '#ca3f13', // 12: Auth0
        '#e0364c', // 13: themeColor--AlizarinCrimson
    ],
    warning: [
        '#ffbf00', // 0: Warning
        '#ff8400', // 1: Medium Warning
    ],
    success: [
        '#00b16a', // 0: Success
        '#8cf864', // 1: SuccessGradient Default
        '#40f877' // 2: SuccessGradient Other
    ],
    error: [
        '#f64744', // 0: Error
        '#EC3D3A', // 1: Darken 4%
        '#FF5B58', // 2: Lighten 8%
    ],
    grayscale: [
        '#bababa', // 0: GreyShade
        '#c1c1c1', // 1: GreyDark
        '#D8D8D8', // 2: Grey
        '#f1f1f1', // 3: GreyAlt
        '#F3F3F3', // 4: GreyLight
        '#fafafa', // 5: DarkWhite
        '#F9F9F9', // 6: DarkerWhite
        '#fcfcfc', // 7: #fff Darken 1%
        '#eeeeee', // 8:a
        '#fbfbfb', // 9:
        '#f5f5f5', // 10:
        '#e2e3ea', // 11: today-highlight-bg
    ],
    text: [
        '#607581', // 0: Heading
        '#595959', // 1: HeadingLight
        '#505561', // 2: Text
        '#797979', // 3: TextDark
        '#abacb8', // 4: Heading Lighten 22%
    ],
    border: [
        '#e9e9e9', // 0: Border
        '#d8d8d8', // 1: BorderDark
        '#ebebeb', // 2: BorderLight
        '#d3d3d3', // 3:
        'rgba(228, 228, 228, 0.65)', // 4:
    ],

    calendar: [
        '#905', // 0:
        '#690', // 1:
        '#a67f59', // 2:
        '#07a', // 3:
        '#dd4a68', // 4:
        '#e90', // 5:
    ],

    traffic: [
        '#e2e3ea', // 0: Clean
        '#ff738a', // 1: Fraudulent
        '#ffc584', // 2: Suspicious
        '#6f7581', // 3: Total IVT
        '#2bcaff', // 4: Total traffic color,
        '#B2BD7E', // 5: Visibility
        '#A7BBEC', // 6: TimeOnSite
        '#63595C', // 7: BrandSafety
        '#577590', // 8: TrafficQuality
        '#F4AFB4', // 9: NetImpressions
        '#EFB0A1', // 10: TotalNetImpressions
        '#FFA973', // 11: General IVT
        '#FF4865', // 12: Sophisticated IVT
        '#3f4349', // 13: Total IVT (new)
        '#c2abb1', // 14: BrandSafetyBlocked
    ]
};

theme.fonts = {
    primary: 'Roboto, sans-serif',
    pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default theme;
